<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Detail Berita</span>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440">
      <div class="container-standar mt-10 mb-10 pad-20">
        <div class="row">
          <div class="col-lg-8 pad-20 berita-content card shadow">
            <h4 class="mb-40 font-34 tittle-berita width-90 text-left font-weight-7 text-dark">
              {{content.title}}
              <br />
               <br />
               
              <span class="text-grey font-18 font-weight-4">
                {{getDate(content.date)}} WIB -
                <span class="text-primary font-weight-7">Real Travel</span>
              </span>
            </h4>
            <div class="mb-20 width-100 d-block">
              <social-sharing
                :url="dinamycArticle"
                :title="content.title"
                :description="shortContent"
                quote="Travel Umroh Terbesar"
                hashtags="Real_Travel "
                twitter-user="Real-travel"
                inline-template
              >
                <div>
                  <network network="facebook" class="cursor-pointer d-inline-block float-left ml-0">
                    <img src="@/assets/images/icon/fb.png" alt />
                  </network>
                  <network
                    network="googleplus"
                    class="cursor-pointer d-inline-block float-left ml-2"
                  >
                    <img src="@/assets/images/icon/gplus.png" alt />
                  </network>
                  <network network="twitter" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/tw.png" alt />
                  </network>
                  <network network="whatsapp" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/wa.png" alt />
                  </network>
                  <network network="telegram" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/tele.png" alt />
                  </network>
                  <network network="line" class="cursor-pointer d-inline-block float-left ml-2">
                    <img src="@/assets/images/icon/line.png" alt />
                  </network>
                </div>
              </social-sharing>
            </div>
             <template v-if="content.type == 'artikel'">
            <div class="container-img-detail">
              <img
                class="width-100"
                v-bind:src="content.file"
                alt
              />
            </div> 
           
                <div v-html="content.content"></div> 
            </template>
           <template v-if="content.type == 'youtube'">
                <iframe class="w-100" height="450" :src="'https://www.youtube.com/embed/'+contentstr"   frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div v-html="content.content"></div> 
           </template>
          </div>
          <div class="col-lg-4">
            <div class="card shadow text-left pad-10">
              <span
                class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
              >Berita Terkait</span>
              <div class="cursor-pointer" v-for="(items, i) in posts.data" :key="i">
                <div class="suggest-img" @click.prevent="onSubmit(items.slug)">
                  <div class="cont-img-100">
                    <img class="width-150" v-bind:src="items.file" alt />
                  </div>
                  <span class="title font-14 font-weight-6">
                    {{items.title}}
                    <br />
                    <span class="text-grey font-weight-6 mt-2 font-12 d-inline-block">
                      {{getDate(items.date)}} -
                      <span class="text-primary font-weight-6">Real Travel</span>
                    </span>
                  </span>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SocialSharing from "vue-social-sharing";
import * as moment from 'moment';
import stripHtml from "string-strip-html";
export default {
  components: {
    SocialSharing
  },
  data() {
    return {
       overriddenNetworks: {
        custom: {
          sharer: "https://realtravel.co.id/detail/" + this.$route.params.slug,
          type: "popup"
        }
      },
       baseUrl: "https://realtravel.co.id/",
      content:{},
      posts:[],
      shortContent:null,
      contentstr:null,
        dinamycArticle:
        "https://realtravel.co.id/detail/" + this.$route.params.slug
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getDataDetail();
    this.getData();
    setTimeout(() => {
      this.metaInfo()
    }, 500);
  },
  methods:{
    getDataDetail(){
        this.axios.get('https://api.realtravel.co.id/content/news?slug='+this.$route.params.slug)
        .then( response => {
          this.content = response.data.response.data[0]
        this.contentstr = this.content.file.substr(32)
        // console.log(this.contentstr)
         this.contentDesc();
         this.metaInfo()
        }).catch( e =>{
          this.err.push(e)
        });
        // console.log();
        
    },
    getData(){
        this.axios.get('https://api.realtravel.co.id/content/news?type=artikel')
        .then( response => {
          this.posts = response.data.response

        }).catch( e =>{
          // console.log(e);
          this.err.push(e)
        });
    },
     contentDesc(){
       this.shortContent = stripHtml(this.content.content).substring(0, 50)+'...'
      
    },
    getDate(val){
      return moment(val).format('dddd, DD-MM-YYYY')
    },
    bulan(val){
      return moment(val).format('MMM')
    },  
    onSubmit(slug){
      this.$router.push('/berita/detail/'+slug);
      this.getDataDetail();
    },
    
  },
    name: "detail-berita",
     metaInfo() {
        return {
          title: this.content.title,
          meta: [
            {
              name: "description",
              content:  'RealTravel Umroh terbesar',
            },
            { name: "keywords", content:this.content.title },
            { property: "og:title", content: this.content.title }, 
            { property: "og:type", content:this.content.title},
            { property: "og:url",  content: this.baseUrl + this.$route.params.slug },
            { property: "og:image", content: this.content.file },
            { property: "og:site_name", content: 'Real Travel'},
            { property: "og:image:width", content: '360' },
             { property: "og:image:height", content: '360' },
          ]
        };
      }
     
};
</script>